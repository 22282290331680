<template>
  <ResizableColumnContainer :maxCol="2" @total-size="onTotalSize">
    <resizable-column
      :columnIndex="0"
      @column-resize="campaignColumnResize"
      ref="list"
      :style="listStyle"
    >
      <MockupForm :data="mockupData" @change="onDataChange"></MockupForm>
    </resizable-column>
    <resizable-column
      :columnIndex="1"
      @column-resize="campaignColumnResize"
      ref="card"
      :style="cardStyle"
    >
      <MockupPreview :data="mockupData"></MockupPreview>
    </resizable-column>
  </ResizableColumnContainer>
</template>
<script>
import { ref } from "vue";
import ResizableColumn from "@/components/ResizableColumn";
import MockupForm from "@/components/mockup/MockupForm";
import MockupPreview from "@/components/mockup/MockupPreview";
import ResizableColumnContainer from "@/components/ResizableColumnContainer";
import Placeholders from "@/assets/placeholders.json";

import {
  list,
  listStyle,
  card,
  cardStyle,
  onTotalSize,
  campaignColumnResize,
} from "@/utils/ColumnsResizer";

export default {
  components: {
    ResizableColumn,
    ResizableColumnContainer,
    MockupForm,
    MockupPreview,
  },
  setup() {
    const mockupData = ref({
      title: "titolo pagina",
      sponsor: "sponsor",
      content: Placeholders.mockup.post.content,
      link: "questo è un link",
      reactions: "12",
      comments: "180",
      shares: "27",
      postImageUrl: false,
      pageImageUrl: false,
    });
    const onDataChange = ({ key, value }) => {
      mockupData.value[key] = value;
    };

    return {
      onDataChange,
      campaignColumnResize,
      list,
      listStyle,
      card,
      cardStyle,
      onTotalSize,
      mockupData,
    };
  },
};
</script>
